import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useRef,
  } from 'react';
  import reducer from './reducer';
  import dayjs from 'dayjs';
  
  const initialState = {
    sectionRefs: {}, // Object to hold refs,
    currentUser: null,
    currentRestaurant:{public_key:null,name:null, email:null,hostname:null,address:null,phone_number:null,description:null,instagram_url:null,facebook_url:null,twitter_url:null,tripadvisor_url:null,google_my_business_url:null,linkedin_url:null,youtube_url:null,},
    openLogin: false,
    openCookieBanner: true,
    openMenu:{open:false,scroll:true,animate:false},
    openAdvancedCookieBanner:false,
    openGallery:false,
    loading: false,
    authLoading: true,
    alert: { open: false, severity: 'info', message: '' },
    dialog: {open: false, close: false, id: 0, title: '', message: '', onSumbit: undefined },
    profile: { open: false, file: null, photoURL: '' },
    //cookieSettings --> essentials: true, functional:false, experience: false, stats:false, marketing:false <-- DEFAULT VALUES
    cookieSettings: {essentials: true, functional:null, experience: null, stats:true, marketing:null},
    //businessDetails
    businessDetails: {name:null, vat_number:null,fic_access_token:null, address: {state: null, cap: null, province: null, street_number: null, city: null, address:null}},
    //restaurantDetails
    restaurantDetails: {business_id: null,name: null, description: null, email:null, image_url:null, alt_image_url:null, address: {state: null, cap: null, province: null, street_number: null, city: null, address:null}, phone_number : null, instagram_url : null, facebook_url :null, twitter_url : null, tripadvisor_url : null, 
    google_my_business_url : null, linkedin_url : null, youtube_url :null, hostname:null}, 
    //supplierDetails
    supplierDetails: {business_id: null, name: null, vat_number: null, description: null, phone_number: null},
    //expenseDetails
    expenseDetails: {business_id: null, supplier_id:null, restaurant_id:null,invoice_number: null, description: null, amount: null, amount_gross:null, amount_vat: null,date_created:null,expiry_date:null, is_extra:null},
    //cashUp
    cashUp: {business_id:null,restaurant_id:null, cash_up_date:null, type:'final',breakfast_covers:null,lunch_covers:null,aperitif_covers:null,dinner_covers:null, bnt:null,cash:null,till:null,extra_expenses:null, notes:null,
    staff:[]},//staff:[{id:null,breakfast:false,lunch:false,aperitif:false,dinner:false,np:false,holiday:false}]
    //invoice
    invoice: {shippingList: [], surchargeList: [],costumer: null, amount: 0, invoiceNumber: '', dateCreated: null,expiryDate: '0'},
    //discount
    discount: {code:null,type:'card', title:null,description:null, costumer_name:null,phone_number:null,email:null,send_email:false,message:null,amount:null,min_amount:null,is_percentage:false,date_expired:null,privacy:false,marketing:false},
    //userProfile
    userProfile: {name: null, surname: null, email: null, phone_number: null, tax_id_code : null, restaurant_id: null, business_id: null,wages: {month:null, wage:null, payslip:null},
    address: {state: 'it', cap: null, city:null, province: null, address:null, street_number: null}, oldPassword: null, newPassword:null, passwordConfirm:null, 
    single_shift:null,double_shift:null,breakfast_shift:null,aperitif_shift:null,duty:null},
    //filters
    restaurantFilter: {daysPanel: null, startDate:null, endDate:null, search: '', orderByColumn: 'date', orderByType:'desc',firstRender:true},
    businessFilter: {days: null, startDate:null, endDate:null, search: '', orderByColumn: 'date', orderByType:'desc',firstRender:true},
    invoiceFilter: {state: null, type: '', days: null, date:null,search: '', orderByColumn: 'date', orderByType:'desc',page:0, limit:20,firstRender:true},
    discountFilter: {state: null, type: '', days: null, startDate:null, endDate:null, search: '', orderByColumn: 'date', orderByType:'desc',page:0, limit:20,firstRender:true},
    userFilter: {days: null, daysPanel: null, startDate:null, endDate:null,role: '',isEnabled:null,search:'',orderByColumn: 'date', orderByType:'desc',page:0, limit:20,firstRender:true},
    cashUpFilter:{days:null,startDate:null, endDate:null, restaurant:null, isFinal:null,search:'',orderByColumn: 'date', orderByType:'desc',page:0, limit:20,firstRender:true},
    shiftFilter:{days:null,startDate:null, endDate:null,restaurant:null,user:null,orderByColumn: 'date', orderByType:'desc',page:0, limit:20,firstRender:true},
    wagesFilter:{days:null,startDate:null, endDate:null,restaurant:null,user:null,orderByColumn: 'date', orderByType:'desc',page:0, limit:20,firstRender:true},
    dashboardFilter:{days:null,startDate:null, endDate:null,restaurant:null,business_id:null,firstRender:true},
    expensesFilter:{days:null,startDate:null, endDate:null, payment_state:null, due_date:null,restaurant:null, supplier:null, search:null,orderByColumn: null, orderByType:null,page:0, limit:20,firstRender:true},
    suppliersFilter:{business:null,supplier:null,hasTag:null ,search:'',orderByColumn: null, orderByType:null,page:0, limit:20, daysPanel:null, startDate:null, endDate:null,firstRender:true},
    //cashUpError
    cashUpError:{bnt:false,cash:false,till:false,staff:false},
    //userError
    userError: {name: false, surname: false, email: false, phone_number: false, tax_id_code:false, address: {state:false, cap:false, city:false, cap:false, province:false, address:false, street_number:false} ,oldPassword: false, newPassword:false, passwordConfirm:false,
    wages: {month:false, wage:false, payslip:false},
    single_shift:false,double_shift:false,duty:false},
    //businessError
    businessError: {name: false, vat_number: false, address: {state:false, cap:false, city:false, cap:false, province:false, address:false, street_number:false}},
    //restaurantError
    restaurantError: {name: false, description: false, email:false, phone_number:false,address: {state:false, cap:false, city:false, cap:false, province:false, address:false, street_number:false}},
    //supplierError
    supplierError: {name: false},
    //expenseError
    expenseError: {business_id:false, supplier_id:false ,amount_gross:false, },
    //discountError
    discountError: {code:false,type:false, title:false,description:false,costumer_name:false,email:false,phone_number:false,card_type:false,amount:false,minAmount:false,isPercentage:false,daysValid:false},
    //other stuff
    currentYear: null,
    user: null,
    contactForm: {name: '', email:'', message:''},
    prevPage: null,
    selectedDiscount:null,
    selectedShiftRow:null,
    selectedWageRow:null,
    showHidden:false,
    showVat:true,
    colors: {bodyColor: '#25CA8C', backgroundColor: '#cccccc', isLight: false, isDarkMode:false},
  };
  
  const Context = createContext(initialState);
  
  export const useValue = () => {
    return useContext(Context);
  };
  
  const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const mapRef = useRef();
    const containerRef = useRef();
    
    useEffect(() => {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser) {
        
        dispatch({ type: 'UPDATE_CURRENT_USER', payload: currentUser });
      }
    }, []);

    useEffect(() => {
      // Attempt to retrieve the currentRestaurant from local storage
      const storedRestaurant = localStorage.getItem('currentRestaurant');
      if (storedRestaurant) {
        const currentRestaurant = JSON.parse(storedRestaurant);
        dispatch({ type: 'UPDATE_CURRENT_RESTAURANT', payload: currentRestaurant });
      }
    }, []);

    useEffect(()=>{
      const currentYear = dayjs().year()
      dispatch({ type: 'UPDATE_CURRENT_YEAR', payload: currentYear });
    },[])
  
  
    return (
      <Context.Provider value={{ state, dispatch, mapRef, containerRef }}>
        {children}
      </Context.Provider>
    );
  };
  
  export default ContextProvider;
  
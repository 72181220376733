
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import Dashboard from 'features/admin/Dashboard';
import  {Home} from 'pages/Home';
import {Loading} from 'components/Loading';
import {Notification} from 'components/Notification';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useValue } from 'context/ContextProvider';
import React, { useEffect, Suspense, lazy} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { GlobalStyles } from '@mui/material';
//import { Register } from 'pages/Register';
import {Box,CircularProgress} from '@mui/material';
import { getRestaurantByHostname, getCurrentVersion } from 'features/admin/restaurants/RestaurantsApi';
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query'
import { lighten, darken, getLuminance } from 'polished';
const Dashboard = lazy(() => import('features/admin/Dashboard'));
const Register = lazy(() => import('pages/Register').then(module => ({ default: module.Register })));


const App = () => {
  //get restaurant public data with useQuery
  // THINK ABOUT A WAY TO STORE THEMES IN THE DB


  // Function to decide contrast text color based on luminance


  const {state: {colors, currentRestaurant, loading},dispatch} = useValue();
  const  { isLight, isDarkMode} = colors;
  const queryClient = useQueryClient();
  const currentHostname = process.env.NODE_ENV === 'development' 
  ? process.env.REACT_APP_DEV_HOSTNAME 
  : window.location.hostname;

  //update favicon
/*   function changeFavicon(iconURL) {
    const link = document.querySelector("link[rel~='icon']");
    console.log(iconURL)
    if (link && link.href === window.location.origin + iconURL) {
      // The favicon is already set to the desired icon, so no further action is needed
      return;
    }
  
    if (link) {
      link.href = iconURL;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = iconURL;
      document.head.appendChild(newLink);
    }
  }

  function changeManifest(manifestURL) {
    let link = document.querySelector('link[rel="manifest"]');
    const fullURL = manifestURL.startsWith('http') ? manifestURL : window.location.origin + manifestURL;
  
    if (link) {
      link.href = fullURL;
    } else {
      link = document.createElement('link');
      link.rel = 'manifest';
      link.href = fullURL;
      document.head.appendChild(link);
    }
  }
 */

  const getContrastText = (color) => {
    // A threshold of 0.5 is a good starting point for deciding on light or dark text
    // This value can be adjusted based on your needs or specific accessibility standards
    return getLuminance(color) > 0.5 ? '#000' : '#fff';
  };
  
  const generateColorShades = (color = '#555') => {
    return {
      main: color,
      light: lighten(0.2, color), // Lighten the color by 20%
      dark: darken(0.1, color), // Darken the color by 20%
      contrastText: getContrastText(color), // Automatically decide contrast text color
    };
  };


const hostnames = currentRestaurant?.hostname;
const isIncluded = hostnames?.includes(currentHostname);
// get currentRestaurant from local storage
const currentRestaurantLS = JSON.parse(localStorage.getItem('currentRestaurant') || 'null');
const {isLoading} = useQuery(
['restaurant_hostname'],() => getRestaurantByHostname(currentHostname),
{ 
  enabled: ((isIncluded !== undefined && !isIncluded) || currentRestaurantLS === null),
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
      onError: (data)=> {
        dispatch({
          type: 'UPDATE_ALERT',
            payload: {
              open: true,
              severity: 'error',
              message: <>
            <div>{data.response.data.detail}</div></>
            },}) 
        dispatch({ type: 'END_LOADING' }) },
    onSuccess: (data)=> {
      //store data inside context using useMemo
      dispatch({ type: 'UPDATE_CURRENT_RESTAURANT', payload: data})
      dispatch({ type: 'END_LOADING' })},
  }
)

const {currentVersionIsLoading} = useQuery(
['current_version'],() => getCurrentVersion(currentRestaurantLS?.hostname, currentRestaurantLS?.last_updated),
{ 
  enabled: !!currentRestaurantLS,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
      onError: (data)=> {
        dispatch({
          type: 'UPDATE_ALERT',
            payload: {
              open: true,
              severity: 'error',
              message: <>
            <div>{data.response.data.detail}</div></>
            },}) 
        dispatch({ type: 'END_LOADING' }) },
    onSuccess: (data)=> {
        //store data inside context using useMemo
        if (data) {
            dispatch({ type: 'UPDATE_CURRENT_RESTAURANT', payload: data})
        }
        dispatch({ type: 'END_LOADING' })},
  }
) 

//updates local storage if currentRestaurant is updated
useEffect(() => {
  const interval = setInterval(() => {
    // Now we're passing a function to setInterval
    queryClient.invalidateQueries(['current_version']).catch(error => {
      console.error("Failed to invalidate queries:", error);
    });
  }, 60000); // Poll every minute

  return () => clearInterval(interval); // Cleanup on unmount
}, []);

//loading circular
useEffect(()=>{
  if ((isLoading  || currentVersionIsLoading)&& !loading && currentRestaurant){
   dispatch({ type: 'START_LOADING' })
  } 
},[isLoading, currentVersionIsLoading])

//update favicon
function updateWebAppIconsAndManifest({faviconUrl, appleTouchUrl, msTileUrl, manifestUrl}) {
  // Update favicon
  updateLink("link[rel~='icon']", faviconUrl);

  // Update Apple touch icon
  updateLink("link[rel~='apple-touch-icon']", appleTouchUrl);

  // Update MS Tile image
  updateMetaContent("meta[name='msapplication-TileImage']", msTileUrl);

  // Update the web app manifest
  updateLink("link[rel='manifest']", manifestUrl);
}

function updateLink(selector, href) {
  const link = document.querySelector(selector);
  if (link) {
    link.href = href.startsWith('http') ? href : window.location.origin + href;
  } else {
    const newLink = document.createElement('link');
    newLink.rel = selector.match(/\[rel~?='([^']+)'\]/)[1];
    newLink.href = href.startsWith('http') ? href : window.location.origin + href;
    document.head.appendChild(newLink);
  }
}

function updateMetaContent(selector, content) {
  const meta = document.querySelector(selector);
  if (meta) {
    meta.content = content.startsWith('http') ? content : window.location.origin + content;
  } else {
    const newMeta = document.createElement('meta');
    newMeta.name = selector.match(/\[name='([^']+)'\]/)[1];
    newMeta.content = content.startsWith('http') ? content : window.location.origin + content;
    document.head.appendChild(newMeta);
  }
}

// Example usage within useEffect, assuming currentRestaurantLS and its public_key are defined
useEffect(() => {
  if (currentRestaurantLS?.public_key) {
    const baseIconPath = `/icons/${currentRestaurantLS.public_key}`;
    updateWebAppIconsAndManifest({
      faviconUrl: `${baseIconPath}/favicon.ico`,
      appleTouchUrl: `${baseIconPath}/apple-touch-icon.png`,
      msTileUrl: `${baseIconPath}/mstile-150x150.png`,
      manifestUrl: `${baseIconPath}/site.webmanifest`,
    });
  }
}, [currentRestaurantLS]);

  const theme =  React.useMemo(
    () =>
      createTheme({
        typography: {
          h1: {
            fontFamily:'roboto',
            fontSize:'3rem',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1  // Your desired size
            // You can add other styles here as needed
          },
          h2: {
            fontSize:'2.5rem',
            fontFamily:'roboto',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1             // Your desired size
            // You can add other styles here as needed
          },
          h3: {
            fontSize: '2rem',
            fontFamily:'roboto',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
          h4: {
            fontSize: '1.8rem',
            fontFamily:'roboto',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
          h5: {
            fontSize: '1.5em',
            fontFamily:'roboto',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
          h6: {
            fontSize: '1.3rem',
            fontFamily:'roboto',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
        },
        palette: {
          mode:isDarkMode ? 'dark' : 'light',
          primary: generateColorShades(currentRestaurantLS?.primary_color ? currentRestaurant.primary_color : '#005A8D') ,
          secondary: generateColorShades(currentRestaurantLS?.secondary_color ? currentRestaurant.secondary_color : '#00AEEF'),
          grey: generateColorShades(currentRestaurantLS?.grey_color ? currentRestaurant.grey_color : '#F4F4F4'),
          backGround: generateColorShades(currentRestaurantLS?.background_color ? currentRestaurant.background_color : '#BDBDBD'),
          red: generateColorShades(currentRestaurantLS?.red_color ? currentRestaurant.red_color : '#E57373'),
          green: generateColorShades(currentRestaurantLS?.green_color ? currentRestaurant.green_color : '#81C784'),
          orange: {
            main: '#f8a220',
            light: '#fab652',
            dark: '#df8807',
            contrastText: '#000',
          },
        },
        components: {
          MuiIconButton: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: 'transparent',
                  color:currentRestaurantLS ? currentRestaurant.secondary_color :'#00AEEF',
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                // Override hover styles for the notched outline
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: isLight ? '#b4e8f8' : isDarkMode?  '#f6f6f6' :'#b4e8f8'  , // Directly use the color
                  transition: 'border-color 0.3s ease',
                },
                // Override background color on hover
                /* '&:hover': {
                  backgroundColor: 'rgba(255, 204, 204, 1)', // Directly use the color
                  transition: 'background-color 0.3s ease',
                }, */
                // Set the border width
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '2px',
                },
                
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                // Normal state color
                //color: 'green', // Change to your desired color
                // Focused state color
                /* '&.Mui-focused': {
                  color: 'blue', // Change to your desired color
                }, */
             /*     '&:hover': {
                  color: isLight ? '#b4e8f8' : isDarkMode?  '#f6f6f6' :'#b4e8f8' , // Directly use the color
                  transition: 'color 0.3s ease',
                },  */
              },
            },
          },
          transitions: {
            duration: {
              shortest: 150,
              shorter: 200,
              short: 250,
              // most basic recommended timing
              standard: 300,
              // this is to be used in complex animations
              complex: 375,
              // recommended when something is entering screen
              enteringScreen: 225,
              // recommended when something is leaving screen
              leavingScreen: 195,
            },
            easing: {
              // This is the most common easing curve.
              easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
              // Objects enter the screen at full velocity from off-screen and
              // slowly decelerate to a resting point.
              easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
              // Objects leave the screen at full velocity. They do not decelerate when off-screen.
              easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
              // The sharp curve is used by objects that may return to the screen at any time.
              sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
            },
          },
         /*  MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: (isLight && !isDarkMode) ? '#b4e8f8' : '#f6f6f6', // Replace with your desired color
                // If you want to make this dynamic based on dark/light mode, you can use:
                // backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#424242' : '#f5f5f5',
              },
            },
          }, */
        },
      }),
    [isDarkMode, currentRestaurant], );

  const Menu = lazy(() => import('pages/Menu').then(module => ({ default: module.Menu })));
  const Vini = lazy(() => import('pages/Vini').then(module => ({ default: module.Vini })));
  return (
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles/>
     
      <Loading />
      <Notification />
      <BrowserRouter>
      <Routes>
          <Route path='*' element={<SuspenseWrapper component={Home} />} />
          <Route path='dashboard/*' element={<SuspenseWrapper component={Dashboard} />} />
          <Route path='register' element={<SuspenseWrapper component={Register} />} />
          <Route path='menu' element={<SuspenseWrapper component={Menu} />} />
          <Route path='vini' element={<SuspenseWrapper component={Vini} />} />
      </Routes>
      </BrowserRouter>
     </ThemeProvider>
  );
};

export default App;

function SuspenseWrapper({ component: Component }) {
  return (
    <Suspense fallback={
      <Box sx={{minHeight:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <CircularProgress aria-label="Loading..."/>
      </Box>
    }>
      <Component />
    </Suspense>
  );
}





